export const interactionConfig = {
    "tooltip": {
        "covid_19_data": {
            "earthquakes": [
                "confirmed",
                "deceased",
                "Longitude",
                "Depth",
                "Magnitude"
            ]
        },
        "enabled": true
    }
}